import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "on-record",
      "style": {
        "position": "relative"
      }
    }}>{`On Record`}<a parentName="h1" {...{
        "href": "#on-record",
        "aria-label": "on record permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Here we archive all gaming guild recordings from KB5 onwards.`}</p>
    <h2 {...{
      "id": "kb5",
      "style": {
        "position": "relative"
      }
    }}>{`KB5`}<a parentName="h2" {...{
        "href": "#kb5",
        "aria-label": "kb5 permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Create a Blockchain Game Speedrun with Austin Griffith`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/8E1qjeVQFmY" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Creating Gameplay with Phaser and Richard Davey`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/QwGmY5vQhsY" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Creating/Designing for GameFi with Ryan Foo`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/txXseVN-Urs" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Infinite Onchain Games with Ronan Sanford`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/VcwNEkk6I9g" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Community Governance & Online Gaming with Nathan Schneider`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/RJHPHLF2PW4" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`The Hyperverse with Timshel, Lord of Lore at Loot`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/RO-ysR_lejA" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Infinite Games with cryptowanderer`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/47xvQ6GuP18" mdxType="Video" />
      </Box>
    </Column>
    <h2 {...{
      "id": "kb7",
      "style": {
        "position": "relative"
      }
    }}>{`KB7`}<a parentName="h2" {...{
        "href": "#kb7",
        "aria-label": "kb7 permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Consilience`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/u564KM_N1hE?start=180" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Infinite Games and Board Games`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/WIG8AObloBM" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Games We Should Build`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/dbKRU0YrV-E" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`The Open Metaverse`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/duOprjuMxdE" mdxType="Video" />
      </Box>
    </Column>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      